import { SpacerVertical, TitlePrimary, Typography } from '@klarna/bubble-ui'
import { Flex } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React, { ComponentProps } from 'react'

import promotionBannerKec from '../../../../../assets/promotion-banner-desktop-kec.png'
import promotionBannerOsm from '../../../../../assets/promotion-banner-desktop-osm.png'
import promotionBannerSiwk from '../../../../../assets/promotion-banner-desktop-siwk.png'
import BannerCard from './BannerCard'

const containerFlexProps: ComponentProps<typeof Flex> = {
  style: {
    maxWidth: '1132px',
    margin: '0 auto',
    gap: '32px'
  },
  children: null
}

const bannerCardContainerFlexProps: ComponentProps<typeof Flex> = {
  justifyContent: 'space-between',
  style: {
    gap: 'calc(10% / 3)',
    flexWrap: 'nowrap',
    width: '100%'
  },
  children: null
}

const bannerCards = [
  {
    imageSrc: promotionBannerOsm,
    titleTranslationKey: 'home-fe.boosters-home-v03.promotion-banner.banner-cards.osm-title'
  },
  {
    imageSrc: promotionBannerKec,
    titleTranslationKey: 'home-fe.boosters-home-v03.promotion-banner.banner-cards.kec-title'
  },
  {
    imageSrc: promotionBannerSiwk,
    titleTranslationKey: 'home-fe.boosters-home-v03.promotion-banner.banner-cards.siwk-title'
  }
]

export default function DesktopView() {
  const t = i18nHooks.useTranslator()

  return (
    <Flex {...containerFlexProps}>
      <div>
        <TitlePrimary>{t('home-fe.boosters-home-v03.promotion-banner.title')}</TitlePrimary>
        <SpacerVertical spaceToken="space/200" />
        <Typography
          textToken='text-style/text/paragraphs/body/regular'
          accessibilityPreset='paragraph'
        >
          {t('home-fe.boosters-home-v03.promotion-banner.subtitle')}
        </Typography>
      </div>

      <Flex {...bannerCardContainerFlexProps}>
        {bannerCards.map((card) => (
          <BannerCard key={card.titleTranslationKey} imageSrc={card.imageSrc} titleTranslationKey={card.titleTranslationKey} />
        ))}
      </Flex>
    </Flex>
  )
}
