import { usePageContext } from '@merchant-portal/framework'
import React, { useEffect } from 'react'

import Page from './components/Page'
import WaitForMids from './components/WaitForMids'
import { AccessProvider } from './hooks/useAccess'
import { ActiveBoostProductsProvider } from './hooks/useActiveBoostProducts'
import { ApiKeyProvider } from './hooks/useApiKey'
import { ClientIdProvider } from './hooks/useClientId'
import { CollapsibleStepsProvider } from './hooks/useCollapsibleSteps'
import { ErrorProvider } from './hooks/useError'
import { MidProvider } from './hooks/useMid'
import { PluginProvider } from './hooks/usePlugin'
import { ShowMidSelectorProvider } from './hooks/useShowMidSelector'


export default function BoostPluginPage () {
  const { setPageContext = () => {} } = usePageContext()

  useEffect(() => {
    setPageContext({
      title: ''
    })
  }, [setPageContext])

  return (
    <ErrorProvider>
      <PluginProvider mustExtractPluginIdFromUrl={false}>
        <MidProvider>
          <ShowMidSelectorProvider>
            <AccessProvider>
              <ApiKeyProvider>
                <ClientIdProvider>
                  <ActiveBoostProductsProvider>
                    <CollapsibleStepsProvider>
                      <WaitForMids>
                        <Page />
                      </WaitForMids>
                    </CollapsibleStepsProvider>
                  </ActiveBoostProductsProvider>
                </ClientIdProvider>
              </ApiKeyProvider>
            </AccessProvider>
          </ShowMidSelectorProvider>
        </MidProvider>
      </PluginProvider>
    </ErrorProvider>
  )
}
