import { useApiKeys } from '@merchant-portal/credentials'

type Props = {
  selectedMid: string | null;
}

export type ApiCredentials = {
  username: string;
  password: string;
}

export const useCreateApiKey = ({ selectedMid }: Props): () => Promise<ApiCredentials> => {
  const { createApiKey } = useApiKeys({ mid: selectedMid ?? '', fetchingApiKeysInitially: false })

  return async () => {
    if (!selectedMid) {
      throw new Error('SelectedMid is required to create an API key.')
    }

    const { credentialId, secret } = await createApiKey()
    return { username: credentialId, password: secret }
  }
}
