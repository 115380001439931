import * as Sentry from '@sentry/react'
import insights from 'mage-insights'
import React, { createContext, useContext } from 'react'

import { useAccess } from './useAccess'
import { ApiCredentials, useCreateApiKey } from './useCreateApiKey'
import { useError } from './useError'
import { useHasActiveApiKeys } from './useHasActiveApiKeys'
import { useMid } from './useMid'
import { usePlugin } from './usePlugin'

export const DEFAULT_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.default'
export const CREATE_API_KEY_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.create-api-key'
export const CREATE_API_KEY_ERROR_ID = 'create-api-key'

export type ApiKey = {
  createApiKeyData: any;
  hasAlreadyActiveKeys: boolean;
  areActionsEnabled: boolean;
  isLoading: boolean;
  isSuccessModalOpen: boolean;
  createApiKey: () => Promise<void>;
  onSuccessModalClose: () => void;
}

type ApiKeyProviderProps = {
  children: React.JSX.Element;
}

export const ApiKeyContext = createContext<ApiKey | undefined>(undefined)

export const ApiKeyProvider = ({
  children
}: ApiKeyProviderProps): React.JSX.Element | null => {
  const { selectedMid } = useMid()
  const { canCreateApiKey } = useAccess()
  const { setErrorToTranslate } = useError()
  const { pluginId } = usePlugin()
  const { hasAlreadyActiveKeys, setHasAlreadyActiveKeys } = useHasActiveApiKeys({ mid: selectedMid })

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState<boolean>(false)
  const [createApiKeyData, setCreateApiKeyData] = React.useState<any>(undefined)

  const areActionsEnabled = !!selectedMid && canCreateApiKey

  const createApiKeyFn = useCreateApiKey({ selectedMid })

  const createApiKey = async () => {
    if (selectedMid) {
      insights.event({
        category: `boost-home/plugin-guide/${pluginId}/generate-api-key`,
        action: 'click',
        label: selectedMid ?? undefined
      })
      try {
        setIsLoading(true)
        const data = await createApiKeyFn()
        handleCreationSuccess(data)
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleCreationSuccess = (data: ApiCredentials) => {
    setCreateApiKeyData(data)
    setIsSuccessModalOpen(true)
    setHasAlreadyActiveKeys(true)
  }

  const handleError = (error: any) => {
    const sentryErrorId = Sentry.captureException(error)
    setErrorToTranslate(
      CREATE_API_KEY_ERROR_KEY,
      CREATE_API_KEY_ERROR_ID,
      sentryErrorId
    )
  }

  const onSuccessModalClose = () => {
    setIsSuccessModalOpen(false)
  }

  const apiKey: ApiKey = {
    createApiKeyData,
    areActionsEnabled,
    hasAlreadyActiveKeys,
    isLoading,
    isSuccessModalOpen,
    createApiKey,
    onSuccessModalClose
  }
  return (
    <ApiKeyContext.Provider value={apiKey}>{children}</ApiKeyContext.Provider>
  )
}

export const useApiKey = (): ApiKey => {
  const value = useContext(ApiKeyContext)

  if (!value) throw new Error('cannot get ApiKeyContext has not been provided')

  return value
}
